.heroImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.header {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  letter-spacing: 3px;
}

.message {
  font-weight: 500;
  font-size: 34px;
  color: white;
  letter-spacing: 1.1px;
}
