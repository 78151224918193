.dynamicContent {
  color: '#212121';
  text-align: left;
}

.dynamicContent img {
  border-radius: 8px;
}

.dynamicContent h1 {
  color: #3B9FC3;
  position: relative;
  border-bottom: 1px solid #ddd;
  padding-top: 24px;
  padding-bottom: 24px;
}

.dynamicContent a {
  color: #0074AF;
}

@media screen and (max-width: 640px) {
  .dynamicContent img {
    height: auto;
    width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
